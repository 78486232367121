<template>
  <b-modal
    v-model="modal"
    :modal-class="`base-modal ${modalClass}`"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    :no-enforce-focus="true"
  >
    <close-button @click="close" />

    <slot />
  </b-modal>
</template>

<script>
import CloseButton from "../common/CloseButton.vue";

export default {
  name: "BaseModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: "",
    },
  },
  components: {
    CloseButton,
  },
  computed: {
    modal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    close() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.base-modal {
  .modal-dialog,
  .modal-content {
    width: auto !important;
    max-width: none !important;
    justify-content: center;
  }

  .modal-content {
    border-radius: 12px;
  }

  .modal-body {
    padding: 0 !important;
  }
}
</style>
